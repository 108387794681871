import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import TransactionTypeIconModel from "../Project/transactionTypeIconModel"
import AxiosInstance from "../../../../services/axiosInstance"
import { Link } from "gatsby"

const AssetTypeStatus = ({ assetTypeStatusData, setAssetTypeStatusData, assetTypeEditId }) => {
  const [showStatusSection, setShowStatusSection] = useState(false),
    [showModal, setShowModal] = useState(false),
    [statusLabel, setStatusLabel] = useState(""),
    [industryIcon, setIndustryIcon] = useState("0-circle"),
    [iconColor, setIconColor] = useState("black"),
    [iconBgColor, setIconBgColor] = useState("#eae8e8"),
    [showAssetStatus, setShowAssetStatus] = useState(false)

  const handleStatusSection = (status, event) => {
    event.preventDefault()
    setShowStatusSection(status)
  }

  const handleSaveStatus = () => {
    if (!statusLabel) {
      alert("Please add status label.")
      return false
    }

    const url = `/settings/asset-type-status/add-status/${assetTypeEditId}`

    AxiosInstance.post(url, {
      icon: industryIcon,
      iconColor: iconColor,
      backgroundColor: iconBgColor,
      label: statusLabel,
    })
      .then(response => {
        const newStatusData = {
          id: response.data.id,
          icon: industryIcon,
          label: statusLabel,
          iconColor: iconColor,
          backgroundColor: iconBgColor,
        }
        setAssetTypeStatusData(assetTypeStatusData => [...assetTypeStatusData, newStatusData])
        setIndustryIcon("0-circle")
        setIconColor("black")
        setIconBgColor("#eae8e8")
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleDeleteStatus = statusId => {
    if (statusId) {
      return new Promise(async () => {
        await AxiosInstance.delete(`/settings/asset-type-status/delete-status/${statusId}`)
          .then(function (response) {
            if (response.status === 200) {
              setAssetTypeStatusData(assetTypeStatusData =>
                assetTypeStatusData.filter(item => item.id !== statusId)
              )
            }
          })
          .catch(function (error) {
            alert("There is some error Please try after sometime.")
          })
      })
    }
  }

  return (
    <div className={`row mb-3 align-items-center${!assetTypeEditId ? " d-none" : ""}`}>
      <div className="col-sm-12">
        <table id="tblAssetStatus" className="table table-striped dataTable no-footer" width="100%">
          <thead>
            <tr>
              <th width="5%">&nbsp;</th>
              <th width="75%">
                <span className="asset_type_status_label">Status</span>
              </th>
              <th width="20%" />
            </tr>
          </thead>
          <tbody>
            {assetTypeStatusData.length === 0 && (
              <tr className={`nullAssetStatusRow${showStatusSection ? " d-none" : ""}`}>
                <td colSpan={3} align="center">
                  No Custom Status Added Yet.{" "}
                  <a
                    href="#"
                    className="btnAddNewStatus dashedLinked"
                    onClick={e => {
                      handleStatusSection(true, e)
                    }}
                  >
                    Click here to add one
                  </a>
                  .
                </td>
              </tr>
            )}
            {assetTypeStatusData.map((status, index) => {
              return (
                <tr key={index}>
                  <td className="text-center tableColChkDel">
                    <div className="tableColChkDelBox">
                      <input
                        type="checkbox"
                        name="statusFileid[]"
                        id={`assetStatus_ID${status.id}`}
                        className="css-checkbox"
                      />
                      <label htmlFor={`assetStatus_ID${status.id}`} className="css-checkbox-label"></label>
                      <span
                        className={`tableColChkSpan color-${status.iconColor}`}
                        style={{ backgroundColor: status.backgroundColor }}
                      >
                        <i className={`bi bi-${status.icon}`}></i>
                      </span>
                    </div>
                  </td>

                  <td>
                    <Link to={``} target="_blank">
                      {status.label}
                    </Link>{" "}
                  </td>

                  <td className="text-end tableColAction d-none d-md-table-cell">
                    <a
                      className="btn btn-delete"
                      onClick={() => {
                        handleDeleteStatus(status.id)
                      }}
                    >
                      <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                        <i className="bi bi-trash-fill" />
                      </OverlayTrigger>
                    </a>
                  </td>
                </tr>
              )
            })}

            <tr className="addNewAssetStatusRow">
              <td colSpan={3}>
                <div className={`addNewAssetStatusInputs${!showStatusSection ? " d-none" : ""}`}>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <a
                      href="#"
                      className="btnCancelAddNewAssetStatus"
                      data-bs-toggle="tooltip"
                      onClick={e => {
                        handleStatusSection(false, e)
                      }}
                    >
                      <i className="bi bi-x-lg" />
                    </a>
                  </OverlayTrigger>
                  <div className="input-group">
                    <button
                      className={`btn color-${iconColor}`}
                      type="button"
                      style={{ backgroundColor: iconBgColor }}
                      onClick={() => {
                        setShowModal(true)
                      }}
                    >
                      <i className={`bi bi-${industryIcon}`} />
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Status Label"
                      onChange={e => setStatusLabel(e.target.value)}
                    />
                    <button className="btn btn-secondary" onClick={handleSaveStatus}>
                      <i className="bi bi-check2" /> Save
                    </button>
                  </div>
                </div>
                <div className="text-center">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Add new status</Tooltip>}>
                    <a
                      href="#"
                      className={`btnAddNewGreenDashed btnAddNewStatus${showStatusSection ? " d-none" : ""}`}
                      data-bs-toggle="tooltip"
                      onClick={e => {
                        handleStatusSection(true, e)
                      }}
                    >
                      <i className="bi bi-plus-lg" />
                    </a>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <TransactionTypeIconModel
          showModal={showModal}
          setShowModal={setShowModal}
          iconColor={iconColor}
          setIconColor={setIconColor}
          iconBgColor={iconBgColor}
          setIconBgColor={setIconBgColor}
          industryIcon={industryIcon}
          setIndustryIcon={setIndustryIcon}
        />
      </div>
    </div>
  )
}

export default AssetTypeStatus
